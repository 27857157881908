import { Column } from '@/components/global/Grid';

import DynamicComponent from './DynamicComponent';

const ColWrapper = ({
  blok: {
    children,
    mobileColumns,
    tabletColumns,
    desktopColumns,
    noColumnPadding,
    disabled,
    desktopColumnOrder
  }
}) => {
  if (disabled) {
    return null;
  }

  return (
    <Column
      sm={mobileColumns}
      md={tabletColumns}
      lg={desktopColumns}
      desktopOrder={desktopColumnOrder}
      noColumnPadding={noColumnPadding}
    >
      {children.map((blok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
    </Column>
  );
};

export default ColWrapper;
